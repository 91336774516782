<template v-if="order.course">
  <b-modal
    id="messages-modal"
    centered
    size="xl"
    :title="'Messages for Order '+order.order+' - '+(order.course ? order.course.course_number : '')"
    no-enforce-focus
    @shown="shown"
    @hide="hide"
  >
    
    <template>
      <ul>
        <li>UC Order ID: {{ order.order }}</li>
        <li>First Name: {{ order.customer ? order.customer.first_name : "" }}</li>
        <li>Last Name: {{ order.customer ? order.customer.last_name : "" }}</li>
        <li>UOP Student ID: {{ order.customer && order.customer.uop_student_id ? order.customer.uop_student_id : "N/A" }}</li>
        <li>Course Number: {{  order.course ? order.course.course_number : "" }}</li>
        <li>Course Title: {{ order.course ? order.course.course_title : "" }}</li>
        <li>Credit(s): {{ order.course_credits }}</li>
        <li>Course Credit Fee: ${{ order.course_credit_fee }}</li>
        <li>Course Fixed Credit Fee: ${{ order.course_fixed_credit_fee }}</li>
        <li>Total Course Credit Fee: ${{ totalCreditFee }}</li>
      </ul>
      <b-tabs v-model="tabIndex" content-class="mt-3">
        <b-tab 
          title="Templates" 
          active 
          v-if="showTemplates"
        >
          
            <label class="my-3 font-weight-bold">Email Template Triggers:</label>
            <br/>
            <b-button
              v-if="order.wc_order_status == 'processing'"
              variant="primary" 
              class="mr-2 mt-2"
              @click="sendTemplate('awaitingProcess')"
              :disabled="stage === 'processing'"
            >
              Awaiting Processing
            </b-button>
            <b-button
              v-if="order.induction"
              variant="primary" 
              class="mr-2 mt-2"
              @click="sendTemplate('courseApproved')"
              :disabled="stage === 'processing'"
            >
              Course Approved Email
            </b-button>
            <b-button
              v-if="order.course_status"
              variant="primary" 
              class="mr-2 mt-2"
              @click="sendTemplate('courseCompletion')"
              :disabled="stage === 'processing'"
            >
              Course Completion Email
            </b-button>
            <b-button
              v-if="order.course_status && order.grade_type === true && order.grade_status"
              variant="primary"
              class="mr-2 mt-2"
              @click="sendTemplate('bannerGradePosted')"
              :disabled="stage === 'processing'"
            >
              Grade Posted via Banner Email
            </b-button>
            <b-button
              v-if="order.wc_order_id"
              variant="primary"
              class="mr-2 mt-2"
              @click="sendTemplate('orderConfirmation')"
              :disabled="stage === 'processing'"
            >
              Order Confirmation Email
            </b-button>
            <br/>
            <label
              v-if="order.workflow_logs && order.workflow_logs.length > 0"
              class="my-3 font-weight-bold"
            >
              Template Trigger History:
            </label>
            <br/>
            <div
            v-for="(entry, key) in order.workflow_logs" 
            :key="key"
            class="note"
            >
              <div class="mb-3">
                <b-avatar 
                  class="mr-2" 
                  variant="primary" 
                  size="2.5rem"
                  style="vertical-align: text-bottom; font-size: 1rem"
                >
                  <strong>{{entry.author_name ? entry.author_name.charAt(0) : 'UC'}}</strong>
                </b-avatar>
                <div class="d-inline-block">
                  <span v-if="entry.author">
                    <strong>{{entry.author_name}} - {{getReadableRole(entry.author_role)}}</strong>
                  </span>
                  <span v-if="!entry.author">
                    <strong>University Credit System</strong>
                  </span>
                  <br/>
                  <span>{{formatDate(entry.date)}}</span>
                </div>
              </div>
              <p class="mb-1 font-weight-bold">Triggered {{getReadableTemplateName(entry.workflow)}}</p>
          </div>
        </b-tab>
        <b-tab 
          title="Personalized Messages" 
          v-if="order.wc_order_id && this.$store.state.user.can_view_messages"
        >
          <b-alert show variant="warning"><strong>Caution:</strong> Adding a message here will send a email to the customer.</b-alert>
          <div
            v-for="(entry, key) in order.messages" 
            :key="key"
            class="note"
          >
            <div class="mb-3">
              <b-avatar 
                class="mr-2" 
                variant="primary" 
                size="2.5rem"
                style="vertical-align: text-bottom; font-size: 1rem"
              >
                <strong>{{entry.author_name.charAt(0)}}</strong>
              </b-avatar>
              <div class="d-inline-block">
                <span><strong>{{entry.author_name}} - {{getReadableRole(entry.author_role)}}</strong></span>
                <br/>
                <span>{{formatDate(entry.date)}}</span>
              </div>
            </div>
            <p class="mb-1 font-weight-bold">{{entry.subject}}</p>
            <div class="mb-2">
              <span 
                v-if="entry.to"
              >
                TO: {{entry.to}}
              <br/>
              </span>
              <span 
                v-if="entry.cc && entry.cc.length > 0"
              >
                CC: {{entry.cc.toString()}}
                <br/>
              </span>
              <span 
                v-if="entry.bcc && entry.bcc.length > 0"
              >
                BCC: {{entry.bcc.toString()}}
                <br/>
              </span>
              <span 
                v-if="entry.attachments && entry.attachments.length > 0"
              >
                Attachments: {{entry.attachments.toString()}}
                <br/>
              </span>
            </div>
            <div v-html="entry.message"></div>
            <!---<br v-if="entry.attachments && entry.attachments.length > 0" />-->
          </div>
          <div v-if="!order.messages || order.messages.length == 0">
            <p class="text-center font-weight-bold py-3">No messages have been sent yet</p>
          </div>
          <div class="newMessageContainer mt-3">
            <label class="my-3 font-weight-bold">New Message</label>
            <br/>
            <b-input-group prepend="Subject" class="mt-3">
              <b-form-input v-model="newMessageSubject"></b-form-input>
            </b-input-group>
            <br/>
            <b-input-group prepend="CC     " class="mt-3">
              <b-form-tags
                placeholder=""
                tag-variant="light"
                v-model="newMessageCc"
                :tag-validator="emailValidator"
                invalid-tag-text="Invalid email(s)"
              ></b-form-tags>
              <template #invalid-feedback>
                You must provide at least 3 tags and no more than 8
                </template>
            </b-input-group>
            <br/>
            <b-input-group prepend="BCC      " class="mt-3">
              <b-form-tags 
                placeholder=""
                tag-variant="light"
                v-model="newMessageBcc"
                :tag-validator="emailValidator"
                invalid-tag-text="Invalid email(s)"
              ></b-form-tags>
            </b-input-group>
            <br/>
            <label>Body</label>
            <editor
              id="tinyMceEditor"
              v-if="showTinyMce"
              v-model="newMessage"
              api-key="bx6l5xj6x1fw2vhdqjnti9oonhlhkbemfcnaj7siy48c3nod"
              :init="{
                height: 250,
                menubar: false,
                plugins: [
                  'advlist autolink lists link charmap preview anchor emoticons',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | fontselect fontsizeselect | bold italic underline forecolor backcolor emoticons | \
                  bullist numlist outdent indent | removeformat | help'
              }"
            />
            <br/>
            <label>Attachments</label>
            <b-form-file
              multiple
              v-model="newMessageFiles"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </div>
        </b-tab>
      </b-tabs>
    </template>
    <template
      #modal-footer="{
        /*cancel hide, ok */
      }"
    >
      <b-button
        v-if="!showTemplates || tabIndex == 1"
        variant="success"
        @click="sendMessage()"
        :disabled="stage === 'processing' || newMessage == ''"
      >
        Send Message
        <b-spinner
          style="width: 20px; height: 20px"
          v-if="stage === 'processing'"
          label="Loading..."
        ></b-spinner>
      </b-button>
      <b-button
        v-if="showTemplates && tabIndex == 0"
        variant="primary"
        @click="closeModal()"
      >
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  data(){
    return {
        showTinyMce: false,
        newMessageSubject: '',
        newMessage : '',
        newMessageCc: [],
        newMessageBcc: [],
        newMessageFiles: [],
        tabIndex: 0,
        stage: ""
      };
  },
  components: {
    'editor': Editor
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    stage(newVal){
      if(newVal === 'success' || newVal === 'error') {
        this.$emit('closeDialog')
        this.$root.$emit('bv::hide::modal', 'notes-modal')
      }
    }
  },
  computed: {
    showTemplates() {
      return this.$store.state.user.can_view_templates && 
             (this.order.course_status || this.order.wc_order_id || this.order.induction)
    },
    totalCreditFee() {
      return (
        (this.order.course_credits ?? 0) * (this.order.course_credit_fee ?? 0) +
        (this.order.course_fixed_credit_fee ?? 0)
      );
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeDialog')
      this.$root.$emit('bv::hide::modal', 'messages-modal')
    },
    emailValidator(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    getReadableTemplateName(template) {
      var readableTemplate;
      switch(template) {
        case 'awaitingProcess':
          readableTemplate = "Awaiting Process"
          break;
        case 'courseApproved':
          readableTemplate = "Course Approved"
          break;
        case 'postCourseCompletion':
          readableTemplate = "Course Completion"
          break;
        case 'courseCompletion':
          readableTemplate = "Course Completion"
          break;
        case 'bannerCourseCompletion':
          readableTemplate = "Banner Course Completion"
          break;
        case 'insidePacificCourseCompletion':
          readableTemplate = "Inside Pacific Course Completion"
          break;
        case 'bannerGradePosted':
          readableTemplate = "Banner Grade Posted"
          break;
        case 'orderConfirmation':
          readableTemplate = "TF Order Confirmation"
          break;
        default:
          readableTemplate = ""
      }
      return readableTemplate+" Email Template"
    },
    getReadableRole(user_role) {
      var readableRole;
      switch(user_role) {
        case 'admin':
          readableRole = "UC Administrator"
          break;
        case 'uop_admin':
          readableRole = "UOP Administrator"
          break;
        case 'partner':
          readableRole = "Partner"
          break;
        default:
          readableRole = ""
      }
      return readableRole
    },
    shown() {
      this.showTinyMce = true;
      this.newMessageSubject = 'Regarding your Course '+((this.order && this.order.course) ? "- "+this.order.course.course_number : "")
    },
    hide() {
      this.showTinyMce =
       false;
    },
    sendTemplate(workflow) {

      this.stage = 'processing'
      if(
        !confirm(
          'Please confirm that a '+this.getReadableTemplateName(workflow)+' should be sent to '+
          (this.order.customer ? this.order.customer.first_name : "")+" "+
          (this.order.customer ? this.order.customer.last_name : "")+ " ("+
          this.order.email+")"
        )
      ) {
        this.stage = ''
        return;
      }
    
      this.$store
        .dispatch("sendPutRequest", {
          endpointPath: "orders/"+this.order._id+"/triggerTemplate",
          body: {
            workflow: workflow,
          }
        })
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          this.$emit('closeDialog')
          this.$root.$emit('bv::hide::modal', 'messages-modal')
          this.$parent.changeView();
          this.newMessageFiles = [];
          this.newMessageCc = [];
          this.newMessageBcc = [];

          this.newMessage = ""
          this.stage = ''
        })
        .catch((err) => {
          this.stage = ''
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    sendMessage() {

      this.stage = 'processing'
    
      const data = new FormData();
            data.append('subject', this.newMessageSubject);
            data.append('message', this.newMessage);
            data.append('cc', JSON.stringify(this.newMessageCc));
            data.append('bcc', JSON.stringify(this.newMessageBcc));
      
      for (const file of this.newMessageFiles) {
        data.append('files', file);
      }
      this.$store
        .dispatch("sendMessage", {
          _id: this.order._id, 
          data: data,
        })
        .then((res) => {
          this.makeToast({ message: res.payload.message, variant: "success" });
          this.$emit('closeDialog')
          this.$root.$emit('bv::hide::modal', 'messages-modal')
          this.$parent.changeView();
          this.newMessageFiles = [];
          this.newMessageCc = [];
          this.newMessageBcc = [];

          this.newMessage = ""
          this.stage = ''
        })
        .catch((err) => {
          this.stage = ''
          this.makeToast({ message: err.payload, variant: "danger" });
        });
    },
    formatDate(date) {
      const locale = 'en-US'
      const timeZone = 'America/Los_Angeles'
      return new Date(date).toLocaleDateString(locale, { timeZone: timeZone })+" "+
             new Date(date).toLocaleTimeString(
                locale, 
                { timeZone: timeZone,hour: '2-digit', minute: '2-digit', hour12: true  
              });
    },
  }
};
</script>


<style lang="scss" scoped>
.modal {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.newMessageContainer {
  border-top: 1px solid #888;
}
.note {
  background-color: #f7f7f7;
  padding: 1em;
  margin-right: 3rem;
  border-radius: 1rem;
  span {
    font-size: 0.7rem;
  }
}
.note:not(:first-of-type) {
  margin-top: 1rem;
}
.input-group-prepend .input-group-text {
  min-width: 5rem;
}
</style>
<style>
.hideTabs {
  display: none;
}
#tinymce p {
  margin-block-start: unset;
  margin-block-end: unset;

}
</style>